<template>
    <div>
        <h1>LinkedIn Success</h1>
        <p v-if="accessToken">Access token received: {{ accessToken }}</p>
        <p v-else>No access token found.</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            accessToken: "",
        };
    },
    mounted() {
        // Extract the access token from the URL query parameters
        const urlParams = new URLSearchParams(window.location.search);
        this.accessToken = urlParams.get("access_token");
        console.log("Access token:", this.accessToken);

        // You can now use the access token for further API calls
    },
};
</script>
